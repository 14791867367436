import api from "./api";
import { login, logout } from "../auth";
import { setErrors } from "utils";

export const handleUsers = async () =>
  (await api.post(`/users/list-by-firm/users`)).data;

export const handleUsersByRoles = async (roles) =>
  (await api.post(`/users/list-by-firm-and-roles`, { roles })).data;

export const handleUserLogin = async data => {
  const { email, password, aadToken, appName, appUrl } = data

  const response = !aadToken ? 
    (await api.post(`/users/login`, { email, password })).data :
    (await api.post(`/users/login/microsoft`, { aadToken, appName, appUrl })).data

  return response
};

export const handleAcceptTerms = async token => {
  await api.post(`/users/accept-terms`, {}, {
    headers: {
      'Authorization': token 
    }
  });
};

export const handleUserForgotPassword = async (data, setMessages) => {
  const values = {
    emailTo: data.email,
    forgotPasswordPageUrl: `${process.env.REACT_APP_URL_APP}/recuperar-senha`
  };
  try {
    const response = await api.post(`/users/forgot-password`, values);
    setMessages({ messages: response.data.data.message, alert: "alert-green" });
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUserRecoverPassword = async (
  history,
  token,
  data,
  setMessages
) => {
  try {
    await api.put(`/users/recover-password/${token}`, data);
    return history.push("/login");
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleStoreUser = async (history, data, setMessages) => {
  const values = {
    name: data.name,
    email: data.email,
    roles: data.roles,
    appName: "Arquitetos da Saúde",
    appUrl: process.env.REACT_APP_URL_APP,
  };
  try {
    await api.post(`/users/store-auth-user`, values);
    return history.push(`/usuarios`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUpdateUser = async (history, data, userId, setMessages) => {
  try {
    await api.put(`/users/${userId}/update-auth-user`, data);
    return history.push(`/usuarios`);
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUpdateViewReportOptions = async (data, userId) => {
  await api.put(`/users/${userId}/view-report-options`, data)
}


export const handleUserUpdateFile = async (data, id) => {
  const values = new FormData();
  values.append("file", data);
  try {
    await api.put(`/users/${id}/upload`, values);
    return;
  } catch (error) {
    return error;
  }
};

export const handleUserUpdatePassword = async (history, data, setMessages) => {
  try {
    await api.put(`/users/update-password`, data);
    logout(history);
    return;
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUpdateProfile = async (
  history,
  profileId,
  data,
  setMessages
) => {
  const values = {
    name: data.name,
    email: data.email,
  };

  try {
    await api.put(`/users`, values);
    logout(history);
    return;
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: "alert-orange" });
  }
};

export const handleUserShow = async (id) =>
  (await api.get(`/users/${id}`)).data;

export const handleUserDelete = async (id, history) => {
  await api.delete(`/users/${id}`);
  return history.push("/usuarios");
};
