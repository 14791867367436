import { useContext } from 'react'

import { Context } from 'contexts/context'

import Topbar from 'components/Topbar'
import Sidebar from 'components/Sidebar'
import BtnOrange from 'components/Button/BtnOrange'

import { Body, Content, ContentWrapper } from './styled'

const ErrorComponent = () => {
    return (
        <div 
            style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' ,
                flexDirection: 'column',
                height: '70vh'
            }}
        >
            <h1 
                style={{ 
                    fontSize: '2rem', 
                    marginBottom: '1rem',
                    textAlign: 'center'
                }}
            >
                Sistema inativo no momento
            </h1>

            <BtnOrange
                onClick={() => window.location.reload(false)}
            >
                Tentar Novamente
            </BtnOrange>
        </div>
    )
}

const Base = ChildrenComponent => {
    const ComponentBase = props => {
        const { toggle } = useContext(Context)

        return (
            <Body>
                {!props.error && <Sidebar />}

                <ContentWrapper>
                    <Topbar error={props.error} />
                    
                    <Content toggle={toggle}>
                        {props.error ? (
                            <ErrorComponent />
                        ) : <ChildrenComponent {...props} />}
                    </Content>
                </ContentWrapper>
            </Body>
        )
    }
    
    return ComponentBase
}

export default Base