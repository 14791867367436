import { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleStoreReport } from 'services/api/reports'
import { handleCards } from 'services/api/reportCards'
import { handleUserShow } from 'services/api/users'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Checkbox from 'components/Form/LabelCheckbox'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import { Context } from 'contexts/context'

const Page = () => {
  const params = useParams()

  const [cards, setCards] = useState([])
  const [user, setUser] = useState({})

  const { user: { firm_id } } = useContext(Context)

  useEffect(() => {
    (async () => {
      setUser(await handleUserShow(params.id))
    })()
  }, [params.id])

  useEffect(() => {
    (async () => {
      try {
        setCards(await handleCards(firm_id))
      } catch(error) {}
    })()
  }, [firm_id])

  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const history = useHistory()
  const formik = useFormik({
    initialValues: { user_id: params.id, roles: '', page_navigation: false, card: {} },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () => handleStoreReport(history, formik.values, params.id, setMessages)
  })

  const breadcrumb = [
    { path: '/usuarios', label: 'Index' },
    { path: `/usuarios/${params.id}/relatorios`, label: 'Relatórios' }
  ]

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Usuário" />
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Permissão</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.roles}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Select name="card" label="Relatórios" formik={formik}>
                  <option value="">Selecione...</option>
                  {cards.map(item => <option key={item.id} value={JSON.stringify(item)}>{item.name}</option>)}
                </Select>
                <Input name="roles" label="Roles" formik={formik} />
                <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={false} />
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Page