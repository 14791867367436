import styled from 'styled-components'

export const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100%;  
`

export const PaginationContainer = styled.section`
    .pagination {
        display: flex;
        justify-content: space-between;
        list-style: none;
        cursor: pointer;
    }

    .pagination a {
        padding: 10px;
        border-radius: 10px;
        border: 1px solid var(--color-icons);
        color: var(--color-icons);
        margin: 0 5px;
    }

    .pagination__link {
        font-weight: bold;  
    }

    .pagination__link--active a {
        color: #fff;
        background: var(--color-icons);
    }

    .pagination__link--disabled a {
        color: rgb(198, 197, 202);
        border: 1px solid rgb(198, 197, 202);
    }

    @media (max-width: 720px) {
        .pagination a {
            margin: 0 3px;
            font-size: 15px;
        }
    }
`