import styled from 'styled-components'

export const CardTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey);
    color: var(--color-font);
    & p {
        padding: 15px 0;
        padding-left: 30px;
        /* flex-grow: 1; */
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    }
    & div {
        padding: 15px;
    }
    @media (min-width: 1140px) {
        & {
            /* flex-direction: column; */
        }
    }

`