import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Select from 'components/Form/Select'
import Table from 'components/Table'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import api from 'services/api/api'

import { handleUserShow } from 'services/api/users'

import { setErrors } from 'utils'

const validation = yup => ({
    // reports: yup
    //   .array()
    //   .min(1, 'Campo obrigatório'),
})

const NewUserReportsCollection = () => {
    const [user, setUser] = useState({})

    const [reportsCollections, setReportsCollections] = useState([])
    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: {},
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => {
        const group_id = formik.values.collection.value

        try {
          await api.post(`/users-collections`, {
            user_id: user.id,
            group_id
          })

          history.push(`/usuarios/${user.id}/relatorios`)
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
        (async () => {
            setUser(await handleUserShow(params.id))
        })()
    }, [params.id])

    useEffect(() => {
        (async () => {
            try {
              const { data } = await api.get(`/collections/by-firm`)

              setReportsCollections(data)
            } catch(error) {

            }
          })()
    }, [])

    return (
        <>
        <TitleBar label="Usuários" currentPage="" />

        <Content>
          <Card>
            <CardTitle title="Usuário" />

            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Permissão</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.roles}</td>
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card>
            <CardTitle title="Cadastrar"></CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Select 
                    name="collection" 
                    label="Grupo" 
                    options={reportsCollections.map(reportsCollection => ({
                      value: reportsCollection.id,
                      label: reportsCollection.name
                    }))}
                    formik={formik}
                  >
                </Select>
                  
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewUserReportsCollection