import { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { handleUserLogin, handleAcceptTerms } from 'services/api/users'
import { login } from 'services/auth'

import Input from 'components/Form/LabelInput'
import Checkbox from 'components/Form/LabelCheckbox'
import Content from 'components/Content'
import BtnOrange from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Modal from 'components/Modal'

import { setErrors } from "utils"
import Validation from './validation'

import { 
    Login, SubTitle, InputBox, 
    BtnBox as PersonalizedBtnBox, ForgotPassword, Form 
} from './styled'

const Page = () => {
    const [visibleModal, setVisibleModal] = useState(false)
    const [isLoading, setIsloading] = useState(false)

    const [response, setResponse] = useState()

    const [messages, setMessages] = useState({ 
        messages: [], alert: '' 
    })

    const history = useHistory()

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: async () => {
            try {
                const data = await handleUserLogin(formik.values)



                if (!data.user.first_access) {
                    setResponse(data)
                    setVisibleModal(true)

                    return
                }

                login(data.token)

                if (data.user.roles === "admin") {
                    history.push(`/grupos`)
                } else {
                    history.push(`/relatorios`)
                }
            } catch(error) {
                if (error.response) {
                    setMessages({ messages: setErrors(error) || 'KKk', alert: "alert-orange" });
                    return
                  }
              
                  setMessages({ messages: 'Sistema inativo no momento!', alert: "alert-orange" });
            }
        }
    })

    const handleButton = async () => {
        setIsloading(true)

        try {
            await handleAcceptTerms(response.token)

            login(response.token)

            if (response.user.roles === "admin") {
                history.push(`/grupos`)
            } else {
                history.push(`/relatorios`)
            }
        } catch(error) {
            setMessages({ messages: 'Houve um problema ao aceitar os termos e condições!', alert: "alert-orange" });
        } finally {
            setIsloading(false)
        }
    }

    return (
        <>
            <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
                <Card>
                    <Content>
                        <div style={{ maxWidth: '800px' }}>
                            <CardTitle 
                                title='O usuário, ao utilizar a plataforma de BI da Arquitetos da saúde, explicitamente aceita aos termos e condições a seguir.' 
                            />

                            <CardBody>
                                <ol>
                                    <li>
                                        O USUÁRIO declara a sua inequívoca ciência que o acesso às plataformas BI será mediante login/senha, que é pessoal e intransferível e em hipótese alguma deverá ser fornecida a terceiros, devendo respeitar todos os procedimentos para acesso, bem como os equipamentos necessários, a exemplo, mas não se limitando à hardware/software atualizados, internet banda larga de alta velocidade, entre outros.                        
                                    </li>

                                    <li>
                                        O USUÁRIO é o único responsável pelas operações efetuadas através de seu acesso (login/senha), respondendo por todos os danos que causar nesse sentido, seja a Arquitetos da Saúde ou a terceiros.
                                    </li>

                                    <li>
                                        O USUÁRIO expressamente autoriza a Arquitetos da Saúde a utilizar as informações especificamente para gerar estatísticas de utilização da Plataforma, com a finalidade de auxiliar o monitoramento e/ou detecção de atividades maliciosas ou vedadas por estes Termos de Uso e Política de Privacidade, bem como de proteger as informações do Usuário do acesso por terceiros não autorizados.
                                    </li>

                                    <li>
                                        O USUÁRIO informará imediatamente a Arquitetos da Saúde qualquer uso não autorizado de seu login/senha que tenha conhecimento, bem como alterar sua senha utilizando letras maiúsculas, minúsculas, números e caracteres especiais.
                                    </li>
                                </ol>

                                <Checkbox 
                                    on
                                    name='acceptedTerms' 
                                    label='Aceito os termos e condições' 
                                    formik={formik} 
                                />

                                <BtnBox>
                                    {!formik.values.acceptedTerms ? (
                                        <BtnWhite 
                                            disabled={formik.values.acceptedTerms}
                                        >   
                                        Concluir
                                        </BtnWhite>
                                    ) : (
                                        <BtnRed 
                                            onClick={handleButton}
                                            disabled={formik.values.acceptedTerms}
                                        >
                                        {isLoading ? <Spinner /> : 'Concluir'}
                                        </BtnRed> 
                                    )}

                                </BtnBox>
                            </CardBody>
                        </div>
                    </Content>
                </Card>
            </Modal>

            <Login>
                <h1>Login</h1>

                <SubTitle>Entre com o seu email e senha.</SubTitle>

                <Form onSubmit={formik.handleSubmit}>
                    <Messages formMessages={messages.messages} alert={messages.alert} />
                    
                    <InputBox>
                        <Input
                        labelColor="var(--color-green)"
                        type="email"
                        name="email"
                        label="Email"
                        formik={formik}
                        />
                    </InputBox>

                    <InputBox>
                        <Input
                        labelColor="var(--color-green)"
                        type="password"
                        name="password"
                        label="Senha"
                        formik={formik}
                        />
                    </InputBox>

                    <ForgotPassword>
                        <Link to="/esqueci-senha">Esqueceu a senha?</Link>
                    </ForgotPassword>
                    
                    <PersonalizedBtnBox>
                        <BtnOrange type="submit" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <Spinner /> : "Entrar"}
                        </BtnOrange>
                    </PersonalizedBtnBox>
                </Form>
        </Login>
      </>
    )
}

export default Page